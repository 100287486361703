import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL + "/";
let http;
class ReportService {
  constructor() {
    http = axios.create({
      baseURL: API_URL,
      headers: authHeader()
    });
    http.interceptors.request.use(
      config => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.token) {
          config.headers.Authorization = "Bearer " + user.token;
        }
        return config;
      },
      error => Promise.reject(error)
    );
  }

  createReport(data) {
    return http.post(`report`, data, {
      user_id: data.user_id,
      organization_id: data.organization_id,
      organization_name: data.organization_name,
      quiz_id: data.quiz_id,
      quiz_name: data.quiz_name,
      course_id: data.course_id,
      organization_data: "",
      location_id: "",
      location_name: "",
      location_data: "",
      wgtype_id: data.wgtype_id,
      wgtype_name: "",
      wgtype_data: "",
      wgtype_template: "",
      workergroup_id: data.workergroup_id,
      workergroup_name: data.workergroup_name,
      workergroup_data: "",
      quiz_data: "",
      debug_data: data.debug_data
    });
  }

  find({ query }) {
    return http.get(`organization_reports`, {
      params: query
    });
  }

  get({ id, query }) {
    return http.get(`organization_reports/${id}`, {
      params: query
    });
  }

  update({ id, data, query }) {
    return http.put(`report/${id}`, data, {
      params: query
    });
  }

  getUserReport({ id, query }) {
    return http.get(`report/${id}`, {
      params: query
    });
  }

  getReportByUserId({ id, query }) {
    return http.get(`user_reports/${id}`, {
      params: query
    });
  }

  getExtendedReport({ id, query }) {
    return http.get(`extended_report/${id}`, {
      params: query
    });
  }

  getConsolidateReport({ id, query }) {
    return http.get(`consolidated-organization-reports/${id}`, {
      params: query
    });
  }

  postConsolidateReport({ query }) {
    return http.post("consolidated-report", {
      label: query.label,
      report_ids: query.report_ids
    });
  }

  remove({ id }) {
    return http.delete(`report/${id}`);
  }

  removeConsolidate({ id }) {
    return http.delete(`consolidated-report/${id}`);
  }
}

export default new ReportService();
