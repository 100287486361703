<template>
  <div class="dashboard-org innerpage">
    <Header></Header>
    <v-container class="my-3">
      <template v-if="!dataLoading && reportTemplate">
        <v-row>
          <v-col class="text-right"
            ><v-btn @click="exportToPDF" class="mb-3" color="primary"
              >Export to PDF</v-btn
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col md="12">
            <div ref="document" class="certificate-html">
              <div class="inner-border-1">
                <div class="inner-border-2">
                  <img src="/assets/images/ABClogo.jpeg" />
                  <div class="cert-title">Certificate of Training</div>
                  <div class="cert-text">is presented to</div>
                  <div class="fullname">{{ certificate.username }}</div>
                  <div class="cert-text">for sucessfully completing</div>
                  <div class="coursename">{{ certificate.course }} Course</div>
                  <div class="cert-footer">
                    <div class="row-above">
                      <div class="col-left"></div>
                      <div class="col-right">
                        {{ certificate.date }}
                      </div>
                    </div>
                    <div class="row-below">
                      <div class="col-left">
                        <strong>Mona Shum</strong><br />MSc, CIH, Industrial
                        Hygenist
                      </div>
                      <div class="col-right">
                        <strong>Date</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-else-if="dataLoading">
        <!-- loading -->
        <v-col cols="12">
          <p class="text-center text-overline mb-8">Loading new data</p>
          <v-progress-linear
            color="secondary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </template>
      <template v-else-if="reportNotFound">
        <!-- loading -->
        <v-col cols="12">
          <p>
            Report is not found.
          </p>
        </v-col>
      </template>
      <template v-else-if="!reportTemplate">
        <!-- loading -->
        <v-col cols="12">
          <p>
            Please set the report template to view the report.
          </p>
        </v-col>
      </template>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import html2pdf from "html2pdf.js";
import Header from "@/components/Header";
import ReportService from "@/services/report.service";
import CoursesService from "@/services/courses.service";
import moment from "moment";
import { Role } from "@/helpers/roles";
import showdown from "showdown";

export default {
  name: "quizcertificate",
  components: {
    Header
  },
  data() {
    return {
      quiz: {},
      certificate: {
        logo: "",
        title: "",
        username: "",
        course: "",
        date: ""
      },
      report: {},
      reportNotFound: false,
      dataLoading: false,
      snackbar: false,
      alert: ""
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    isAdminEditor() {
      let adminEditor = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.AdminEditor) {
          adminEditor = true;
        }
      });
      return adminEditor;
    },

    catId() {
      return this.$route.query.cat_id;
    },

    quizId() {
      return this.$route.params.id;
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.initialize();
  },

  methods: {
    convert(markdownTxt) {
      let converter = new showdown.Converter();
      this.htmlData = converter.makeHtml(markdownTxt);

      return this.htmlData;
    },
    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },
    async initialize() {
      this.dataLoading = true;

      //get the report
      const response = await ReportService.getExtendedReport({
        id: this.$route.params.id
      });
      this.report = response.data;
      this.certificate.logo = this.report.organization.logo;
      this.certificate.course = this.report.course.title;
      this.certificate.username = this.report.user.name;

      let completed_date = this.report.report.completed_at;
      this.formatDate(completed_date);

      console.log("report", this.report);

      if (this.report) {
        let quizId = this.report.quizzes[0].quiz_id;

        const quizData = await CoursesService.getQuiz(quizId);
        this.quiz = quizData.data.data;
        this.reportTemplate = this.quiz.report_template;

        console.log("quiz", this.quiz);
      }

      this.dataLoading = false;
    },

    exportToPDF() {
      html2pdf(this.$refs.document, {
        margin: 0,
        filename: "Certificate-" + this.certificate.course + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "A4", orientation: "landscape" }
      });
    },

    getOrgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    },

    formatDate(completed_date) {
      this.certificate.date = moment(completed_date).format("MMMM DD, YYYY");
    }
  }
};
</script>
<style scoped>
.certificate-html {
  background: #ffffff;
  border: 6px solid #2f91ae;
  height: 794px;
  padding: 6px;
  text-align: center;
}
.inner-border-1 {
  border: 3px solid #96b93d;
  height: 771px;
  padding: 3px;
}
.inner-border-2 {
  border: 3px solid #96b93d;
  height: 759px;
  box-sizing: border-box;
  padding: 20px 30px;
}
.cert-title {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 40px;
  line-height: 49px;
  color: #2f91ae;
  line-height: 49px;
  padding: 0.75rem 0;
}
.cert-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #666666;
  padding: 0.75rem 0;
}

.fullname {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 50px;
  line-height: 61px;
  padding: 0.75rem 0;
}

.coursename {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #96b93d;
  padding: 0.75rem 0;
}

.cert-footer {
  width: 90%;
  padding-top: 3rem;
  margin: 0 auto;
}

.row-above {
  height: 65px;
}
.row-above .col-left {
  width: 306px;
  border-bottom: 2px solid #666666;
  height: 60px;
  float: left;
}

.row-above .col-right {
  width: 306px;
  height: 60px;
  padding-top: 20px;
  float: right;
  border-bottom: 2px solid #666666;
  box-sizing: border-box;
  color: #000000;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 700;
}

.row-below {
  height: 65px;
}

.row-below .col-left {
  width: 306px;
  height: 60px;
  float: left;
  color: #666666;
  font-family: "Roboto";
}

.row-below .col-right {
  width: 306px;
  height: 60px;
  float: right;
  font-family: "Roboto";
  color: #666666;
}
</style>
